import React, { useEffect } from "react";
import { getAllDocsSnap } from "../../../utils/firebase/Firebase.utils";
import AllResidentsTable from "./AllResidentsTable";
import Modal from "../Components/Modal";
import ResidentsDetails from "./ResidentsDetails";
import AddResidentForm from "../Forms/AddResidentForm";
import ResidentDetails from "./ResidentDetails";
import ResidentsCard from "./ResidentsCard";
import { Link } from "react-router-dom";

function AllResidents({ searchTerm }) {
  const [residentSummary, setResidentSummary] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [viewMode, setViewMode] = React.useState(false);
  const [selectedResident, setSelectedResident] = React.useState({});
  const [activeLink, setActiveLink] = React.useState("Resident Information");
  const [residents, setResidents] = React.useState([]);

  useEffect(() => {
    const fetchResidents = async () => {
      getAllDocsSnap("residents", (residents) => {
        //arrange by owner1
        residents.sort((a, b) => {
          if (a.owner1 < b.owner1) {
            return -1;
          }
          if (a.owner1 > b.owner1) {
            return 1;
          }
          return 0;
        });

        setResidents(residents);
        const mappedResidents = residents.map((resident) => {
          return {
            unitOwner: resident.owner1,
            identifier: resident.identifier,
            id: resident.id,
            unitNumber: `Phase ${resident.phase} Block ${resident.block} ${resident.lot}`,
            occupants: resident.occupants,
            address: resident.address,
            lotSize: resident.lotSize + " sqm",
            phoneNumber: resident.phoneNumber,
            email: resident.email,
            unitType: resident.unitType,
            tenanted: resident.tenanted ? "Yes" : "No",

            documents: "View",
          };
        });

        //arrange asc by identifier
        mappedResidents.sort((a, b) => a.identifier - b.identifier);

        setResidentSummary(mappedResidents);
        setIsLoading(false);
      });
    };

    fetchResidents();
  }, []);

  if (isLoading) {
    return <div>Loading</div>;
  }

  const tableHeaders = [
    "Lot Owner",
    "Phase Block and Lot Number",
    "Lot Size",
    "Occupants",
    "Email Address",
    "Phone Number",
    "Tenanted",
    "More Information",
  ];

  const handleViewDetails = () => {
    setViewMode(true);
  };

  const handleViewItem = (resident) => {
    setSelectedResident(resident.id);
    setViewMode(true);
  };

  const filteredResidents = residents.filter(
    (resident) =>
      resident.owner1?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.owner2?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.identifier?.toString().includes(searchTerm.toLowerCase()) ||
      resident.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.block?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.phase?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resident.lot?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="text-xs">
      <div>
        <div className="mt-4 font-bold text-blue-900 uppercase text-left text-sm">
          All Lot Owners
        </div>

        {/* render button and table */}
        {/* <div className="text-left">
          <button
            className="blue-button"
            disabled={selectedResident.length > 0 ? false : true}
            onClick={handleViewDetails}
          >
            View Details
          </button>
        </div> */}
        <div className=" mt-4 space-y-2 ">
          {filteredResidents.map((resident, index) => (
            <div
              className=""
              key={index}
              // onClick={() => handleViewItem(resident)}
            >
              <Link to={`/resident/${resident.id}`}>
                <ResidentsCard resident={resident} />
              </Link>
            </div>
          ))}
          <div className="hidden lg:flex">
            {/* <AllResidentsTable
              residents={residentSummary}
              setViewMode={setViewMode}
              setSelectedResident={setSelectedResident}
            /> */}
          </div>
        </div>
      </div>

      {/* modal to view the selected lot owner */}
      {viewMode && (
        <Modal>
          <div className="relative lg:w-[900px] p-4 ">
            <div
              className="absolute top-0 right-4 underline cursor-pointer text-xs"
              onClick={() => setViewMode(false)}
            >
              Close
            </div>
            <div className="lg:w-[800px] min-h-[500px] w-[95%]">
              <ResidentDetails
                selectedResident={selectedResident}
                viewMode={viewMode}
                setViewMode={setViewMode}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AllResidents;
