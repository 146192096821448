import React from 'react'

export default function FacilityRequestCard({data}) {
  return (
    <div className="bg-white flex flex-col lg:flex-row items-start lg:items-center rounded-md shadow-sm p-2 cursor-pointer hover:bg-gray-200 text-xs text-left">
    <div className="flex flex-row items-center lg:flex-col lg:items-start w-80">
      <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
        Requestor
      </div>
      <div className="font-bold px-1 text-blue-900">
        {data.user || "Not Set"}
      </div>
    </div>

    <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
      <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
        Duration
      </div>
      <div className="font-bold px-1 text-blue-900">{data.duration}</div>
    </div>

    <div className="flex flex-row items-center lg:flex-col lg:items-start w-60">
      <div className="rounded-full text-[8px] bg-gray-100 w-16 px-1 ">
        Date
      </div>
      <div className="font-bold px-1 text-blue-900 capitalize">
        {data.date}
      </div>
    </div>

    <div className={`capitalize ${data.status.toLowerCase()}`}>
      {data.status}
    </div>
  </div>
  )
}
