import { type } from "@testing-library/user-event/dist/type";
import React, { useEffect } from "react";
import { FiEdit3 } from "react-icons/fi";
import { useSelector } from "react-redux";
import PieChart from "../Charts/PieChart";
import PieChartDashboard from "../Charts/PieChart";
import SimpleBarChartDashboard from "../Charts/SimpleBarChartDashboard";
import BasicDateCalendar from "../Charts/Calendar";
import { Link } from "react-router-dom";
import {
  getAllDocsSnap,
  getAllDocs,
} from "../../utils/firebase/Firebase.utils";
import { useSearchParams } from "react-router-dom";
import Modal from "./Components/Modal";
import SplashScreen from "./SplashScreen";

function AdminDashboard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const splash = searchParams.get("splash");

  const [showModal, setShowModal] = React.useState(false);
  const currentUser = useSelector((state) => state.userObject);
  const [residentSummary, setResidentSummary] = React.useState([]);
  const [selectedJobOrderLink, setSelectedJobOrderLink] =
    React.useState("newJobOrder");

  const firstName = currentUser.displayName.split(" ")[0];
  const [numberOfUnits, setNumberOfUnits] = React.useState(0);
  const [occupiedUnits, setOccupiedUnits] = React.useState(0);
  const [gatepass, setGatepass] = React.useState(0);
  const [visitorPass, setVisitorPass] = React.useState(0);

  useEffect(() => {
    if (splash === "true") {
      setShowModal(true);
    }
  }, [splash]);

  useEffect(() => {
    const fetchgatepass = async () => {
      const gatepass = await getAllDocs("gatePass");
      setGatepass(gatepass.length);
    };

    const fetchVisitorPass = async () => {
      const visitorPass = await getAllDocs("guests");
      setVisitorPass(visitorPass.length);
    };

    fetchgatepass();
    fetchVisitorPass();
  }, []);

  useEffect(() => {
    const fetchResidents = async () => {
      const residentList = getAllDocsSnap("residents", (residents) => {
        setNumberOfUnits(residents.length);
        const occupiedUnits = residents.filter(
          (resident) => resident.occupants > 0
        );
        setOccupiedUnits(occupiedUnits.length);
      });
    };
    fetchResidents();
  }, []);

  const notifications = {
    notifications: 10,
    todos: 15,
  };

  const forApprovals = {
    gatepass: 24,
    visitorPass: 12,
    maintenanceRequest: 5,
    otherRequests: 3,
  };

  const currentDateTime = new Date();

  const newJobOrders = [
    {
      id: 1,
      title: "Job Order 1",
      location: "Bldg B, 27th Floor 27B",
      type: "Maintenance",
      dateTime: "2024-08-09 08:45:00",
    },
    {
      id: 2,
      title: "Job Order 2",
      location: "Location 2",
      type: "Maintenance",
      dateTime: "2024-08-08 08:45:00",
    },
    {
      id: 3,
      title: "Job Order 3",
      location: "Location 3",
      type: "Maintenance",
      dateTime: "2024-08-07 08:45:00",
    },
  ];

  const propertyOverview = {
    totalUnits: numberOfUnits,
    totalOccupied: occupiedUnits,
    totalVacant: numberOfUnits - occupiedUnits,
  };

  const todoList = [
    { id: 1, title: "Check your approval list and request", priority: "High" },
    {
      id: 2,
      title: "Check your approval list and request",
      priority: "High",
    },
    {
      id: 3,
      title: "Check your approval list and request",
      priority: "High",
    },
  ];

  const activeLinkStyle = "text-green-600 border-b-2 border-green-600 p-b-2";

  return (
    <div>
      {showModal && (
        <Modal>
          <div>
            <SplashScreen setShowModal={setShowModal} />
          </div>
        </Modal>
      )}

      {/* Welcome Band */}
      <div className="flex items-center justify-between px-4 py-2 border-b border-gray-300">
        <div className="text-left">
          <div className="text-2xl font-semibold text-blue-900">
            Welcome Back {firstName}!
          </div>
          <div>
            You have{" "}
            <span className="font-bold text-red-700">
              {notifications.notifications}
            </span>{" "}
            notifications and{" "}
            <span className="font-bold text-red-700">
              {notifications.todos}
            </span>{" "}
            to do's. It is a lot of work today!
          </div>
        </div>

        <div className="rounded-lg border border-gray-300 px-4 py-2 hidden lg:flex items-center justify-center">
          <FiEdit3 className="text-2xl text-blue-900" />
          Edit
        </div>
      </div>

      {/* container for the grid */}
      <div className="flex items-start justify-center text-blue-900 flex-wrap">
        {/* first grid */}
        <div className="w-[350px] p-2 flex flex-col gap-2">
          <div className="text-left">
            <div className="text-lg font-bold ">For Approval</div>
            <div className="text-xs text-gray-600">
              {currentDateTime.toLocaleString()}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between text-blue-900">
              <div className="p-2 border rounded-lg border-gray-300 text-left w-40 h-20 relative">
                <div className="uppercase text-xs font-semibold">Gate pass</div>
                <div className="text-3xl font-bold">{gatepass}</div>
                <div className="absolute text-xs right-3 bottom-0 underline">
                  <Link to={`/security?type=Gate+Pass`}>View All</Link>
                </div>
              </div>
              <div className="p-2 border rounded-lg border-gray-300 text-left w-40 h-20 relative text-green-500">
                <div className="uppercase text-xs font-semibold">
                  Visitors Pass
                </div>
                <div className="text-3xl font-bold">{visitorPass}</div>
                <div className="absolute text-xs right-3 bottom-0  underline">
                  <Link to={`/security?type=Guest+Pass`}>View All</Link>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between text-blue-900">
              <div className="p-2 border rounded-lg border-gray-300 text-left w-40 h-20 relative">
                <div className="uppercase text-xs font-semibold">
                  Work Permits
                </div>
                <div className="text-3xl font-bold">
                  {forApprovals.maintenanceRequest}
                </div>
                <div className="absolute text-xs right-3 bottom-0 underline">
                  <Link to={`/property-management?type=Work+Permits`}>
                    View All
                  </Link>
                </div>
              </div>
              <div className="p-2 border rounded-lg border-gray-300 text-left w-40 h-20 relative text-green-500">
                <div className="uppercase text-xs font-semibold">
                  Other Concerns and Requests
                </div>
                <div className="text-3xl font-bold">
                  {forApprovals.otherRequests}
                </div>
                <div className="absolute text-xs right-3 bottom-0  underline">
                  <Link to={`/property-management?type=Concerns`}>
                    View All
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full border border-gray-300 rounded-lg overflow-hidden px-4 py-1">
            <div className="flex items-center justify-between">
              <div className="text-lg font-bold t">Job Order</div>
              <div className="text-xs">See all Job Order</div>
            </div>
            <div className="text-left text-xs">
              {"As of " + currentDateTime.toLocaleString()}
            </div>
            <div className="text-xs flex items-center justify-start gap-4">
              <div
                className={`cursor-pointer
                ${selectedJobOrderLink === "newJobOrder" ? activeLinkStyle : ""}
                    `}
                onClick={() => setSelectedJobOrderLink("newJobOrder")}
              >
                New Job Order
              </div>
              <div
                className={`cursor-pointer
                    ${
                      selectedJobOrderLink === "newPendingOrder"
                        ? activeLinkStyle
                        : ""
                    }
                        `}
                onClick={() => setSelectedJobOrderLink("newPendingOrder")}
              >
                New Pending Order
              </div>
            </div>

            <div className="flex flex-col gap-2 mt-4">
              {selectedJobOrderLink === "newJobOrder"
                ? newJobOrders.map((jobOrder) => (
                    <div
                      key={jobOrder.id}
                      className=" relative flex items-start justify-between border border-gray-300 rounded-lg p-2"
                    >
                      <div className="text-xs absolute bottom-0 right-2 underline">
                        View All
                      </div>
                      <div className="flex flex-col items-start justify-start text-xs">
                        <div className="font-bold">{jobOrder.title}</div>
                        <div>{jobOrder.location}</div>
                        <div className="bg-pink-300 px-2 py-1 rounded-md text-[10px]">
                          {jobOrder.type}
                        </div>
                      </div>
                      <div className="text-xs">
                        {
                          // compute for lapsed hours
                          Math.floor(
                            (currentDateTime - new Date(jobOrder.dateTime)) /
                              3600000
                          ).toString() + " hours ago"
                        }
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
        {/* end of first grid */}

        {/* second grid */}
        <div className="w-[600px] p-2 flex flex-col items-start justify-start gap-2">
          <div className="border border-gray-300 rounded-lg text-left p-1 relative w-full">
            <div className="absolute text-xs p-2 top-0 right-0 underline">
              View Detailed Report
            </div>
            <div className="font-bold ">Property Overview</div>
            <div className="text-xs">
              As of {currentDateTime.toLocaleString()}
            </div>
            <div className="uppercase font-bold">
              Total Homes: {propertyOverview.totalUnits}
            </div>
            <div className="h-72 w-auto mt-8 align-center">
              <PieChartDashboard />
            </div>
          </div>
          <div className="w-full border border-gray-300 rounded-lg p-2 flex flex-col gap-2">
            <div>
              <div className="text-left font-bold  capitalize">
                Dues Collection Overview
              </div>
              <div className="text-xs font-semibold text-left">
                Overview Report: {currentDateTime.toLocaleString()}
              </div>
            </div>
            <div className="h-96 w-auto">
              <SimpleBarChartDashboard />
            </div>
          </div>
        </div>
        {/* end of second grid */}

        {/* third grid */}
        <div className="w-[350px] p-2">
          <div className="border-2 bordergra30 p-2 rounded-lg w-fit">
            <BasicDateCalendar />
          </div>
          <div>
            <div className="flex items-center justify-between mt-4">
              <div className="font-bold">To dos</div>
              <div className="text-xs underline">View All</div>
            </div>
            <div className="flex flex-col gap-2">
              {todoList.map((todo) => (
                <div
                  key={todo.id}
                  className="border-2 border-gray-300 rounded-lg text-left p-2"
                >
                  <div>{todo.title}</div>
                  <div className="bg-yellow-400 px-2 py-1 rounded-lg w-fit text-xs">
                    {todo.priority}
                  </div>
                  <div>Icons here</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* end of third grid */}
      </div>
    </div>
  );
}

export default AdminDashboard;
