import React from "react";

export default function ItemDetailsPage({ data, setViewMode }) {
  return (
    <div className="page relative">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>
      <div className="header">Item Details</div>
      <div className="font-bold">{data.itemName}</div>
    </div>
  );
}
