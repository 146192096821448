import React, { useState } from "react";
import Modal from "./Components/Modal";
import AnnouncementForm from "./Announcements/AnnouncementForm";

function Announcements() {
  const links = ["All", "Announcements", "Newsletters"];
  const [activeLink, setActiveLink] = React.useState("All");
  const [addMode, setAddMode] = useState(false);

  const activeLinkStyle = "bg-green-500 text-black p-2";

  // Dummy announcements data
  const announcements = [
    {
      type: "Announcements",
      title: "Pickle Ball Court Closure",
      description:
        "The pickle ball court will be closed for maintenance from September 15 to September 20.",
      image: "https://via.placeholder.com/300x200?text=Elevator+Maintenance",
    },
    {
      type: "Newsletters",
      title: "Monthly Newsletter - September",
      description:
        "Catch up on all the latest happenings around the condominium for the month of September.",
      image: "https://via.placeholder.com/300x200?text=September+Newsletter",
    },
    {
      type: "Announcements",
      title: "Water Supply Interruption",
      description:
        "There will be a temporary water supply interruption on September 20 from 9 AM to 5 PM.",
      image: "https://via.placeholder.com/300x200?text=Water+Interruption",
    },
    {
      type: "Newsletters",
      title: "August Newsletter",
      description:
        "Check out what happened in the month of August in this edition of the newsletter.",
      image: "https://via.placeholder.com/300x200?text=August+Newsletter",
    },
  ];

  const filteredAnnouncements =
    activeLink === "All"
      ? announcements
      : announcements.filter(
          (announcement) => announcement.type === activeLink
        );

  const handleCreate = () => {
    setAddMode(true);
  };

  return (
    <div className="text-blue-900 p-4 text-xs">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <h1 className="font-bold text-xs lg:text-sm">
          Announcements and Advisory
        </h1>
        <button className="blue-button" onClick={handleCreate}>
          Create
        </button>
      </div>

      {/* Filter Section */}
      <div className="flex items-center lg:justify-between border-2 border-gray-300 p-4 rounded-lg flex-wrap justify-center text-sm gap-2">
        <div className="links-container ">
          {links.map((link, index) => (
            <div
              key={index}
              className={
                activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
              }
              onClick={() => setActiveLink(link)}
            >
              {link}
            </div>
          ))}
        </div>
        <input
          type="search"
          placeholder="Search announcements"
          className="border-2 border-gray-300 px-4 py-2 w-96 rounded-lg"
        />
        <button className="hidden lg:flex ml-2">Filter</button>
      </div>

      {/* Announcements Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredAnnouncements.length === 0 ? (
          <div className="text-gray-600 col-span-full">
            No announcements to show.
          </div>
        ) : (
          filteredAnnouncements.map((announcement, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <img
                src={announcement.image}
                alt={announcement.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4 space-y-2">
                <h3 className="font-bold text-xl text-blue-900">
                  {announcement.title}
                </h3>
                <p className="text-gray-600">{announcement.description}</p>
              </div>
            </div>
          ))
        )}
      </div>

      {addMode && (
        <Modal>
          <AnnouncementForm setAddMode={setAddMode} />
        </Modal>
      )}
    </div>
  );
}

export default Announcements;
