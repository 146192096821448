import React, { useEffect } from "react";
import { FiFilter } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import AddTenantForm from "./Forms/AddTenantForm";
import AddResidentForm from "./Forms/AddResidentForm";
import { getAllDocsSnap } from "../../utils/firebase/Firebase.utils";
import AllResidents from "./TenantManagement/AllResidents";
import TenantDetails from "./TenantManagement/TenantDetails";
import ResidentDetails from "../Dashboard/TenantManagement/ResidentsDetails";
import AllTenants from "./TenantManagement/AllTenants";
import Modal from "./Components/Modal";
import TaliMap from "./TenantManagement/TaliMap";

function TenantManagement() {
  const [searchParams, setSearchParams] = useSearchParams();
  const link = searchParams.get("link");
  const [activeLink, setActiveLink] = React.useState(link);
  const [addMode, setAddMode] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");

  useEffect(() => {
    setSearchParams({ link: activeLink });
  }, [activeLink]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const links = ["Long Term Leases", "Owners", "Map"];

  return (
    <div className="text-xs">
      <div className="text-blue-900 text-left font-bold mt-4 text-sm">
        Resident Management
      </div>

      {/* All links */}
      <div className="flex items-center justify-between gap-2 p-2 flex-wrap">
        <div className="links-container">
          {links.map((link, index) => (
            <div
              key={index}
              className={`${
                activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
              } cursor-pointer`}
              onClick={() => setActiveLink(link)}
            >
              {link}
            </div>
          ))}
        </div>

        {/* buttons  */}
        <div className="flex gap-2">
          <button className="blue-button" onClick={() => setAddMode(true)}>
            Add
          </button>

          <button className="blue-button">Download PDF Report</button>
        </div>
      </div>

      {/* filtering and search */}
      <div className="flex items-center justify-between p-2 border-2 border-gray-300 rounded-lg mt-2">
        <input
          className="border-2 px-4 py-2 rounded-lg border-gray-100 w-96"
          type="search"
          placeholder="Search by name, lot owner, or address"
          onChange={handleSearch}
        />

        <div className="flex items-center gap-2 text-sm">
          <FiFilter className="text-blue-900 text-xl" />
          Filter
        </div>
      </div>

      {/* rendering which active link is active */}

      {activeLink === "Long Term Leases" && (
        <AllTenants searchTerm={searchTerm} />
      )}
      {activeLink === "Owners" && <AllResidents searchTerm={searchTerm} />}
      {activeLink === "Map" && <TaliMap />}

      {/* //add tenant modal  */}
      {addMode && activeLink === "Long Term Leases" && (
        <Modal>
          <div className="lg:min-w-[900px] lg:min-h-[500px] text-sm">
            <div className="text-right text-xs">
              <button className="underline" onClick={() => setAddMode(false)}>
                Close
              </button>
            </div>
            <AddTenantForm setAddMode={setAddMode} addMode={addMode} />
          </div>
        </Modal>
      )}

      {addMode && activeLink === "Owners" && (
        <Modal>
          <div className="lg:min-w-[900px] lg:min-h-[500px] text-sm">
            <div className="text-right text-xs">
              <button className="underline" onClick={() => setAddMode(false)}>
                Close
              </button>
            </div>
            <AddResidentForm setAddMode={setAddMode} addMode={addMode} />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default TenantManagement;
