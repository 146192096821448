import React, { useEffect, useState } from "react";
import {
  setDocData,
  getDocDataSnap,
} from "../../utils/firebase/Firebase.utils";
import InputBox from "./Components/InputBox";
import { useParams } from "react-router-dom";

export default function HistoryInformation({ resident }) {
  const { id } = useParams();
  const [ownersData, setOwnersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const fetchOwnersData = async () => {
      getDocDataSnap("residents", id, (data) => {
        setOwnersData(data);
        setLoading(false);
      });
    };

    fetchOwnersData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleReplace = () => {
    setEditMode(!editMode);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    let previousOwners = ownersData.previousOwners || [];

    const dataObject = {
      ...ownersData,
      owner1: formData.owner1,
      address: formData.address,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      previousOwners: [...previousOwners, ownersData],
    };

    try {
      await setDocData("residents", resident, dataObject);
      setIsProcessing(false);
      setEditMode(false);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className="text-left text-gray-600 text-xs">
      <div className="header">Ownership History</div>
      <div className="flex items-center gap-2">
        <div>Current Owner</div>
        <div>{ownersData.owner1}</div>
      </div>

      <div className="mt-4">
        <button className="blue-button" onClick={handleReplace}>
          {editMode ? "Cancel" : "Replace Owner"}
        </button>
      </div>

      {editMode && (
        <div className="mt-8">
          <form onSubmit={handleSubmit} className="space-y-4">
            <InputBox
              name="owner1"
              label="New Owner"
              type="text"
              placeholder="Name of the new owner"
              value={formData.owner1}
              onChange={handleChange}
              required
            />

            <InputBox
              name="address"
              label="Address"
              type="text"
              placeholder="Address"
              value={formData.address}
              onChange={handleChange}
              required
            />

            <InputBox
              name="email"
              label="Email Address"
              type="email"
              required
              placeholder="Email Address"
              value={formData.email}
              onChange={handleChange}
            />

            <InputBox
              name="phoneNumber"
              label="Phone Number"
              type="text"
              required
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
            />

            <div>
              <button className="blue-button" type="submit">
                {isProcessing ? "Saving" : "Save"}
              </button>
            </div>
          </form>
        </div>
      )}

      {!editMode && (
        <div className="mt-8">
          <div className="header">Previous Owners</div>
          {ownersData?.previousOwners ? (
            ownersData.previousOwners.map((owner, index) => (
              <div key={index} className="flex items-center gap-2">
                <div>{owner.owner1}</div>
                <div>{owner.address}</div>
                <div>{owner.email}</div>
                <div>{owner.phoneNumber}</div>
              </div>
            ))
          ) : (
            <div>No previous owners</div>
          )}
        </div>
      )}
    </div>
  );
}
