import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Signup from "./pages/Login/Signup";
import Dashboard from "./pages/Dashboard/Dashboard";
import { useSelector } from "react-redux";
import ResetPassword from "./pages/Login/ForgotPassword";
import AdminDashboard from "./pages/Dashboard/AdminDashboard";
import TenantManagement from "./pages/Dashboard/TenantManagement";
import UnderConstruction from "./pages/Dashboard/UnderConstruction";
import PropertyManagement from "./pages/Dashboard/PropertyManagement";
import Security from "./pages/Dashboard/Security";
import Facilities from "./pages/Dashboard/Facilities";
import Services from "./pages/Dashboard/Services";

import Announcements from "./pages/Dashboard/Announcements";
import Billing from "./pages/Dashboard/Billing";
import UserDashboard from "./pages/User/UserDashboard";
import UserNavBar from "./pages/User/UserNavBar";
import Profile from "./pages/User/Profile";
import Contacts from "./pages/Dashboard/Contacts";
import Settings from "./pages/Dashboard/Settings";
import UserUnderConstruction from "./pages/User/UnderConstruction";
import MaintenanceRequest from "./pages/User/MaintenanceRequest";
import GuestRegistration from "./pages/User/GuestRegistration";
import AddGuests from "./pages/User/AddGuests";
import GuestCardDetails from "./pages/User/GuestCardDetails";
import Bills from "./pages/User/Bills";
import Paynow from "./pages/User/Paynow";
import RequestSummary from "./pages/User/RequestSummary";
import Tasks from "./pages/User/Tasks";
import WorkPermitUser from "./pages/User/WorkPermitUser";
import DuesUser from "./pages/User/DuesUser";
import FacilitiesUser from "./pages/User/FacilitiesUser";
import SOS from "./pages/User/SOS";
import OthersUser from "./pages/User/OthersUser";
import LaundryUser from "./pages/User/LaundryUser";
import WaterServiceUser from "./pages/User/WaterServiceUser";
import CleaningServicesUser from "./pages/User/CleaningServicesUser";
import OtherServicesUser from "./pages/User/OtherServicesUser";
import InboxUser from "./pages/User/InboxUser";
import AdminUser from "./pages/User/AdminUser";
import NotificationsUser from "./pages/User/NotificationsUser";
import GuestHistory from "./pages/User/GuestHistory";
import HoaDirectory from "./pages/User/HoaDirectory";
import BillingDetails from "./pages/User/BillingDetails";
import PaymentConfirmed from "./pages/User/PaymentConfirmed";
import WorkPermitConfirmation from "./pages/User/WorkPermitConfirmation";
import RequestDetails from "./pages/User/RequestDetails";
import PickleBallReservation from "./pages/User/PickleBallReservation";
import ClubHouse from "./pages/User/ClubHouse";
import ReservationConfirmation from "./pages/User/ReservationConfirmation";
import LifeCalendar from "./pages/User/LIfeCalendar";
import GroupChat from "./pages/User/GroupChat";
import NotificationDetails from "./pages/User/NotificationDetails";
import Gatepass from "./pages/User/Gatepass";
import GatepassConfirmation from "./pages/User/GatepassConfirmation";
import MaintenanceRequestConfirmation from "./pages/User/MaintenanceRequestConfirmation";
import Suppliers from "./pages/User/Suppliers";
import GroupChatHome from "./pages/User/GroupChatHome";
import ConcernsForm from "./pages/User/ConcernsForm";
import ConcernConfirmationPage from "./pages/User/ConcernConfirmationPage";
import ConcernDetails from "./pages/User/ConcernDetails";
import Complaints from "./pages/User/Complaints";
import Inventory from "./pages/Dashboard/Inventory";
import Analytics from "./pages/Dashboard/Analytics";
import WorkPermitDetails from "./pages/Dashboard/WorkPermits/WorkPermitDetails";
import TenantDetails from "./pages/Dashboard/TenantManagement/TenantDetails";
import TenantDetailsFullpage from "./pages/Dashboard/TenantManagement/TenantDetailsFullpage";
import ResidentDetails from "./pages/Dashboard/TenantManagement/ResidentDetails";
import ResidentDetailsFullpage from "./pages/Dashboard/TenantManagement/ResidentDetailsFullpage";
import SecurityNewPage from "./pages/Security/SecurityNewPage";
import { Inventory2 } from "@mui/icons-material";
import Inventoryv2 from "./pages/Dashboard/Inventory/Inventoryv2";

function App() {
  const currentUser = useSelector((state) => state.userObject);

  if (
    !currentUser ||
    !currentUser.email ||
    currentUser.email === "" ||
    currentUser.email === null ||
    currentUser.email === undefined
  ) {
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="signup" element={<Signup />} />
            <Route path="login" element={<Login />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Router>
      </div>
    );
  }

  if (currentUser?.roles?.includes("user")) {
    return (
      <div className="App">
        <div className="relative">
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Dashboard />}>
                <Route index element={<UserDashboard />} />
                <Route path="dashboard" element={<UserDashboard />} />
                <Route path="life" element={<LifeCalendar />} />
                <Route path="bills" element={<Bills />} />
                <Route
                  path="payment-confirmed/:id"
                  element={<PaymentConfirmed />}
                />
                <Route
                  path="billing-details/:id"
                  element={<BillingDetails />}
                />
                <Route path="paynow" element={<Paynow />} />
                <Route path="requests" element={<RequestSummary />} />
                <Route
                  path="request-details/:id"
                  element={<RequestDetails />}
                />
                <Route path="tasks" element={<Tasks />} />
                <Route path="gate-pass" element={<Gatepass />} />
                <Route
                  path="gatepass-confirmation/:id"
                  element={<GatepassConfirmation />}
                />
                <Route path="work-permits" element={<WorkPermitUser />} />
                <Route
                  path="work-permit-confirmation/:id"
                  element={<WorkPermitConfirmation />}
                />
                <Route path="dues" element={<DuesUser />} />
                <Route path="facility-usage" element={<FacilitiesUser />} />
                <Route path="club-house" element={<ClubHouse />} />
                <Route
                  path="reservation-confirmation/:id"
                  element={<ReservationConfirmation />}
                />

                <Route path="pickle-ball" element={<PickleBallReservation />} />
                <Route path="sos" element={<SOS />} />
                <Route path="others" element={<OthersUser />} />
                <Route path="laundry" element={<LaundryUser />} />
                <Route path="water" element={<WaterServiceUser />} />
                <Route path="cleaning" element={<CleaningServicesUser />} />
                <Route path="other-services" element={<OtherServicesUser />} />
                <Route path="suppliers" element={<Suppliers />} />

                <Route path="group chat" element={<GroupChatHome />} />
                <Route path="group-chats/:id" element={<GroupChat />} />
                <Route path="admin" element={<AdminUser />} />
                <Route path="notifications" element={<NotificationsUser />} />
                <Route
                  path="notif-details/:id"
                  element={<NotificationDetails />}
                />
                <Route path="hoa-directory" element={<HoaDirectory />} />
                <Route path="home" element={<UserDashboard />} />
                <Route path="profile" element={<Profile />} />
                <Route path="*" element={<UserUnderConstruction />} />
                <Route
                  path="maintenance-request"
                  element={<MaintenanceRequest />}
                />
                <Route
                  path="maintenance-request-confirmation/:id"
                  element={<MaintenanceRequestConfirmation />}
                />

                <Route path="concerns" element={<ConcernsForm />} />
                <Route path="complaints" element={<Complaints />} />
                <Route path="concern/:id" element={<ConcernDetails />} />
                <Route
                  path="/user/concerns"
                  element={<ConcernConfirmationPage />}
                />
                <Route path="register-guests" element={<GuestRegistration />} />
                <Route path="add-guest" element={<AddGuests />} />
                <Route path="edit-guest-card/:id" element={<AddGuests />} />
                <Route path="guest-history" element={<GuestHistory />} />
                <Route
                  path="guest-card-details/:id"
                  element={<GuestCardDetails />}
                />
              </Route>
            </Routes>
          </Router>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />}>
            <Route index element={<AdminDashboard />} />
            <Route
              path="guest-card-details/:id"
              element={<GuestCardDetails />}
            />
            <Route path="overview" element={<AdminDashboard />} />
            <Route path="resident-management" element={<TenantManagement />} />
            <Route path="resident/:id" element={<ResidentDetailsFullpage />} />☻
            <Route path="tenant/:id" element={<TenantDetailsFullpage />} />
            <Route
              path="property-management"
              element={<PropertyManagement />}
            />
            <Route path="security" element={<SecurityNewPage />} />
            <Route path="inventory" element={<Inventoryv2 />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="facilities" element={<Facilities />} />
            <Route path="services" element={<Services />} />
            <Route path="announcements" element={<Announcements />} />
            <Route path="billing" element={<Billing />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="settings" element={<Settings />} />
            <Route path="*" element={<UnderConstruction />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="work-permits/:id" element={<WorkPermitDetails />} />

          <Route path="/dashboard" element={<Dashboard />}>
            <Route index element={<AdminDashboard />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
