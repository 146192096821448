import React from "react";

export default function AnnouncementForm({ data, setAddMode }) {
  return (
    <div className="small-form relative pt-8">
      <button className="close-button" onClick={() => setAddMode(false)}>
        Close
      </button>
      <div className="header">Announcement Form</div>
    </div>
  );
}
